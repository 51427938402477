__tnt.video.nielsen || (__tnt.video.nielsen = {});

__tnt.video.nielsen.sdkinit  = function (player) {
    __tnt.video.nielsen.nSdkInstance = NOLBUNDLE.nlsQ(nielsen_apid, "nlsnInstance", {containerId: player.id(), nol_sdkDebug: nielsen_debug});

    __tnt.video.nielsen.metaData(player);
}

//Build Metadata
__tnt.video.nielsen.metaData  = function (player) {
    __tnt.video.nielsen.meta =
    {  
      type:             'content',
      assetid:          player.el_.dataset.uuid,
      title:            player.el_.dataset.title,
      length:           player.el_.dataset.duration,
      isfullepisode:    'n',
      adloadtype:       2,
      program:          player.el_.dataset.provider,
    };

    __tnt.video.nielsen.sendMeta(__tnt.video.nielsen.meta);
}

//Send Metadata
__tnt.video.nielsen.sendMeta  = function (contentMetadataObject) {
    __tnt.video.nielsen.nSdkInstance.ggPM('loadMetadata', contentMetadataObject); 
}

//Get playhead position
__tnt.video.nielsen.playHead  = function (player) {
    return Math.floor(player.currentTime()); 
}

//Handle Tracking video progress while playing
__tnt.video.nielsen.track  = function (player) {
    __tnt.video.nielsen.nSdkInstance.ggPM("setPlayheadPosition", __tnt.video.nielsen.playHead(player));
}

//Handle Start Events
__tnt.video.nielsen.start  = function (player) {
    __tnt.video.nielsen.intervalId = setInterval(() => __tnt.video.nielsen.track(player), 1000);
    __tnt.video.nielsen.nSdkInstance.ggPM("play", __tnt.video.nielsen.playHead(player));
    __tnt.video.nielsen.ended = false;
    
}

//Handle Pause/Stop Events
__tnt.video.nielsen.stop  = function (player) {
    // Use the interval ID returned by startInterval() to cancel the interval
    clearInterval(__tnt.video.nielsen.intervalId);
    __tnt.video.nielsen.nSdkInstance.ggPM("stop", __tnt.video.nielsen.playHead(player));
}

//Handle Video Completes event
__tnt.video.nielsen.end  = function (player) {
    // Use the interval ID returned by startInterval() to cancel the interval
    clearInterval(__tnt.video.nielsen.intervalId);
    __tnt.video.nielsen.nSdkInstance.ggPM("end", __tnt.video.nielsen.playHead(player));
    __tnt.video.nielsen.ended = true;
}
